<template>
  <ui-component-modal
    modalTitle="Edit VAT rates"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveTaxPercentages"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth is-striped is-hoverable">
        <thead>
          <tr>
            <th>Rate</th>
            <th width="75">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(percentage, index) in mLocationState.TaxPercentages"
            :key="index"
          >
            <td>
              <div class="field has-addons">
                <p class="control">
                  <input
                    type="text"
                    v-model="percentage.TaxPercentage"
                    class="input"
                    :class="{
                      'is-danger':
                        keyCounter > 0 &&
                        percentage.TaxPercentage === newPercentage,
                    }"
                  />
                </p>
                <p class="control">
                  <a class="button is-static">%</a>
                </p>
              </div>
            </td>
            <td class="has-text-right">
              <a @click="deleteTaxPercentage(index)" class="has-text-danger">
                <span class="icon">
                  <font-awesome-icon :icon="['fa', 'trash-alt']" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div class="field has-addons">
                <p class="control">
                  <input
                    type="text"
                    v-model.number="newPercentage"
                    @keyup.enter="addNewPercentage"
                    @keyup="setKeyCounter"
                    class="input"
                  />
                </p>
                <p class="control">
                  <a class="button is-static">%</a>
                </p>
              </div>
            </td>
            <td class="has-text-right">
              <a @click="addNewPercentage" class="button is-primary">
                <span class="icon">
                  <font-awesome-icon :icon="['fa', 'plus']" />
                </span>
                <span>Add</span>
              </a>
            </td>
          </tr>
        </tfoot>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import taxProvider from '@/providers/tax'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      keyCounter: 0,
      mLocationState: null,
      newPercentage: 0,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
  },

  created() {
    this.mLocationState = JSON.parse(JSON.stringify(this.locationState))
  },

  methods: {
    ...mapMutations('locationStore', ['setLocationState']),

    setKeyCounter() {
      this.keyCounter = this.keyCounter + 1
    },

    addNewPercentage() {
      if (!isNaN(this.newPercentage)) {
        let checkIndex = this.mLocationState.TaxPercentages.findIndex(
          (t) => t.TaxPercentage === this.newPercentage
        )
        if (checkIndex === -1) {
          this.mLocationState.TaxPercentages.push({
            Id: 0,
            LocationId: this.locationState.LocationId,
            TaxPercentage: this.newPercentage,
          })
          this.keyCounter = 0
          this.newPercentage = 0
        }
      }
    },

    deleteTaxPercentage(index) {
      this.mLocationState.TaxPercentages.splice(index, 1)
    },

    saveTaxPercentages() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        taxProvider.methods
          .saveLocationTaxRates(
            self.locationState.Id,
            self.mLocationState.TaxPercentages
          )
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              self.mLocationState.TaxPercentages = response.data
              self.setLocationState(this.mLocationState)

              // Set timer to close popup in in 1,5 seconds
              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
