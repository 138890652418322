import axios from 'axios'
import store from '../store'

export default {
  methods: {
    async getLocationTaxRates(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tax/location/${locationId}/rates`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationTaxRates(locationId, taxRates) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/tax/location/${locationId}/rates`,
        taxRates,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    }
  }
}
